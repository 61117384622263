import React from "react";
import { RiProductHuntLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import "./Home.scss";
import heroImg from "../../assets/home.gif";
import { ShowOnLogin, ShowOnLogout } from "../../components/protect/HiddenLink";
import image1 from "../../assets/image1.jpg";
import image2 from "../../assets/image2.png";
import image3 from "../../assets/image3.png";

const Home = () => {
  return (
    <div className="home">
      <nav className="container --flex-between ">
        <div className="logo">
          <RiProductHuntLine size={35} />
        </div>

        <ul className="home-links">
          <ShowOnLogout>
            <li>
              <Link to="/register">Register</Link>
            </li>
          </ShowOnLogout>
          <ShowOnLogout>
            <li>
              <button className="--btn --btn-primary">
                <Link to="/login">Login</Link>
              </button>
            </li>
          </ShowOnLogout>
          <ShowOnLogin>
            <li>
              <button className="--btn --btn-primary">
                <Link to="/dashboard">Dashboard</Link>
              </button>
            </li>
          </ShowOnLogin>
        </ul>
      </nav>
      {/* HERO SECTION */}
      <section className="container hero">
        <div className="hero-text">
          <h2>Inventory {"&"} Stock Management System</h2>
          <p>
            Inventory system to control and manage products in the drugstore in
            real time and integrated to make it easier to develop your work.Get to know where the medicine stock is easily.
          </p>
          <div className="hero-buttons">
            <button className="--btn">
              <Link to="/">Get your Work Done Easily</Link>
            </button>
          </div>
          
        </div>

        <div className="hero-image">
          <img src={heroImg} alt="Inventory" />
        </div>
      </section>
    
      <section className="container image-section">
        <div className="image-row">
          <div className="image-container">
            <img src={image1} alt="Image 1" />
            <div className="image-overlay">
              <Link to="/">Nutrition Tips</Link>
            </div>
          </div>
          <div className="image-container">
            <img src={image2} alt="Image 2" />
            <div className="image-overlay">
              <Link to="/">Tips for Anxiety</Link>
            </div>
          </div>
          <div className="image-container">
            <img src={image3} alt="Image 3" />
            <div className="image-overlay">
              <Link to="/">Women's Health Tips</Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};





export default Home;
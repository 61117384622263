import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/loader/Loader";
import RequestForm from "../../components/request/requestForm/RequestForm";
import {
  createRequest,
  selectIsLoading,
} from "../../redux/features/request/requestSlice";

const initialState = {
  name: "",
  quantity: "",
};

const AddRequest = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [request, setRequest] = useState(initialState);
  const [requestImage, setRequestImage] = useState("");
  const [imagePreview, setImagePreview] = useState(null);
  const [description, setDescription] = useState("");

  const isLoading = useSelector(selectIsLoading);

  const { name, quantity } = request;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setRequest({ ...request, [name]: value });
  };

  const handleImageChange = (e) => {
    setRequestImage(e.target.files[0]);
    setImagePreview(URL.createObjectURL(e.target.files[0]));
  };

  const saveRequest = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", name);
    formData.append("quantity", Number(quantity));
    formData.append("description", description);
    formData.append("image", requestImage);

    console.log(...formData);

    await dispatch(createRequest(formData));

    navigate("/request-list");
  };

  return (
    <div>
      {isLoading && <Loader />}
      <h3 className="--mt">Send New Request</h3>
      <RequestForm
        request={request}
        requestImage={requestImage}
        imagePreview={imagePreview}
        description={description}
        setDescription={setDescription}
        handleInputChange={handleInputChange}
        handleImageChange={handleImageChange}
        saveRequest={saveRequest}
      />
    </div>
  );
};

export default AddRequest;
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import requestService from "./requestService";
import { toast } from "react-toastify";

const initialState = {
    request: null,
    requests: [],
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
    totalStoreValue: 0,
    outOfStock: 0,
    category: [],

};

//create New request
export const createRequest = createAsyncThunk(
    "requests/create",
    async (formData, thunkAPI) => {
        try {
            return await requestService.createRequest(formData)
        } catch (error) {
            const message =
            (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            console.log(message);
            return thunkAPI.rejectWithValue(message);
        }
    }
);

//Get all requests
export const getRequests = createAsyncThunk(
    "requests/getAll",
    async (_, thunkAPI) => {
        try {
            return await requestService.getRequests();
        } catch (error) {
            const message =
            (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            console.log(message);
            return thunkAPI.rejectWithValue(message);
        }
    }
);

//Delete a request
export const deleteRequest = createAsyncThunk(
    "requests/delete",
    async (id, thunkAPI) => {
        try {
            return await requestService.deleteRequest(id);
        } catch (error) {
            const message =
            (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            console.log(message);
            return thunkAPI.rejectWithValue(message);
        }
    }
);

// Get a request
export const getRequest = createAsyncThunk(
    "requests/getRequest",
    async (id, thunkAPI) => {
      try {
        return await requestService.getRequest(id);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        console.log(message);
        return thunkAPI.rejectWithValue(message);
      }
    }
  );

  // Update request
export const updateRequest = createAsyncThunk(
    "requests/updateRequest",
    async ({ id, formData }, thunkAPI) => {
      try {
        return await requestService.updateRequest(id, formData);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        console.log(message);
        return thunkAPI.rejectWithValue(message);
      }
    }
  );

const requestSlice = createSlice({
    name: "request",
    initialState,
    reducers: {
        CALC_OUTOFSTOCK(state, action) {
            const requests = action.payload
            const array = []
            requests.map((item) => {
                const { quantity} = item;
                return array.push(quantity);
            });
            let count = 0
          array.forEach((number) => {
            if (number === 0 || number === "0" ) {
                count += 1;
            }
          }) 
          state.outOfStock = count 
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(createRequest.pending,(state) => {
               state.isLoading = true;
            })
            .addCase(createRequest.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true; 
                state.isError = false;
                console.log(action.payload); 
                state.requests.push(action.payload);
                toast.success("request added successfully")
            })
            .addCase(createRequest.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;               
                toast.error(action.payload);
            })

            .addCase(getRequests.pending,(state) => {
                state.isLoading = true 
             })
             .addCase(getRequests.fulfilled, (state, action) => {
                 state.isLoading = false;
                 state.isSuccess = true; 
                 state.isError = false;
                 console.log(action.payload); 
                 state.requests = action.payload;
                 
             })
             .addCase(getRequests.rejected, (state, action) => {
                 state.isLoading = false;
                 state.isError = true;
                 state.message = action.payload;               
                 toast.error(action.payload);
             })

             .addCase(deleteRequest.pending,(state) => {
                state.isLoading = true 
             })
             .addCase(deleteRequest.fulfilled, (state, action) => {
                 state.isLoading = false;
                 state.isSuccess = true; 
                 state.isError = false;
                 toast.success("request deleted successfully")
                 
             })
             .addCase(deleteRequest.rejected, (state, action) => {
                 state.isLoading = false;
                 state.isError = true;
                 state.message = action.payload;               
                 toast.error(action.payload);
             })

             .addCase(getRequest.pending, (state) => {
                state.isLoading = true;
              })
              .addCase(getRequest.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.isError = false;
                state.request = action.payload;
              })
              .addCase(getRequest.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
                toast.error(action.payload);
              })

              .addCase(updateRequest.pending, (state) => {
                state.isLoading = true;
              })
              .addCase(updateRequest.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.isError = false;
                toast.success("request updated successfully");
              })
              .addCase(updateRequest.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
                toast.error(action.payload);
              });

             
    },

    

    
});

export const { CALC_OUTOFSTOCK } = requestSlice.actions;

export const selectIsLoading = (state) => state.request.isLoading;
export const selectRequest = (state) => state.request.request;
export const selectTotalStoreValue = (state) => state.request.totalStoreValue;
export const selectOutOfStock = (state) => state.request.outOfStock;
export const selectCategory = (state) => state.request.category;

export default requestSlice.reducer;
import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../redux/features/auth/authSlice";
import productReducer from "../redux/features/product/productSlice";
import filterReducer from "../redux/features/product/filterSlice";
import requestReducer from "../redux/features/request/requestSlice";
import requestFilterReducer from "../redux/features/request/requestFilterSlice";

export const store = configureStore({
    reducer: {
        auth: authReducer,
        product: productReducer,
        filter: filterReducer,
        request: requestReducer,
        requestFilter: requestFilterReducer,
    },
});
import axios from "axios";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const API_URL = `${BACKEND_URL}/api/requests/`;

// Create New request
const createRequest = async (formData) => {
  const response = await axios.post(API_URL, formData);
  return response.data;
};

// Get all requests
const getRequests = async () => {
  const response = await axios.get(API_URL);
  return response.data;
};

// Delete a request
const deleteRequest = async (id) => {
  const response = await axios.delete(API_URL + id);
  return response.data;
};
// Get a request
const getRequest = async (id) => {
  const response = await axios.get(API_URL + id);
  return response.data;
};
// Update request
const updateRequest = async (id, formData) => {
  const response = await axios.patch(`${API_URL}${id}`, formData);
  return response.data;
};

const requestService = {
  createRequest,
  getRequests,
  getRequest,
  deleteRequest,
  updateRequest,
};

export default requestService;

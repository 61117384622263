import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../components/loader/Loader";
import RequestForm from "../../components/request/requestForm/RequestForm";
import {
  getRequest,
  getRequests,
  selectIsLoading,
  selectRequest,
  updateRequest,
} from "../../redux/features/request/requestSlice";

const EditRequest = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoading = useSelector(selectIsLoading);

  const requestEdit = useSelector(selectRequest);

  const [request, setRequest] = useState(requestEdit);
  const [requestImage, setRequestImage] = useState("");
  const [imagePreview, setImagePreview] = useState(null);
  const [description, setDescription] = useState("");

  useEffect(() => {
    dispatch(getRequest(id));
  }, [dispatch, id]);

  useEffect(() => {
    setRequest(requestEdit);

    setImagePreview(
      requestEdit && requestEdit.image ? `${requestEdit.image.filePath}` : null
    );

    setDescription(
      requestEdit && requestEdit.description ? requestEdit.description : ""
    );
  }, [requestEdit]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setRequest({ ...request, [name]: value });
  };

  const handleImageChange = (e) => {
    setRequestImage(e.target.files[0]);
    setImagePreview(URL.createObjectURL(e.target.files[0]));
  };

  const saveRequest = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", request?.name);

    formData.append("quantity", request?.quantity);
    formData.append("description", description);
    if (requestImage) {
      formData.append("image", requestImage);
    }

    console.log(...formData);

    await dispatch(updateRequest({ id, formData }));
    await dispatch(getRequests());
    navigate("/request-list");
  };

  return (
    <div>
      {isLoading && <Loader />}
      <h3 className="--mt">Edit Request</h3>
      <RequestForm
        request={request}
        requestImage={requestImage}
        imagePreview={imagePreview}
        description={description}
        setDescription={setDescription}
        handleInputChange={handleInputChange}
        handleImageChange={handleImageChange}
        saveRequest={saveRequest}
      />
    </div>
  );
};

export default EditRequest;
import React from 'react';

const Footer = () => {
    return (
        <div className='--flex-center --py2'>
             <p>All rights Reserved &copy; 2023</p>
        </div>
       
    );
};

<p>contact us page</p>

export default Footer;